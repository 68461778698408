
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import { Alert } from '~/types'

const alertStore = namespace('alert')

@Component
export default class extends Vue {
  @alertStore.State
  private readonly messages!: Alert[]

  @alertStore.Mutation
  private shiftMessage!: () => void

  get alert (): Alert | null {
    if (this.messages.length === 0) {
      return null
    }
    return this.messages[0]
  }

  protected mounted (): void {
    window.addEventListener('click', this.closeError)
  }

  protected destroyed (): void {
    window.removeEventListener('click', this.closeError)
  }

  private closeError (): void {
    if (!this.messages.length) {
      return
    }
    const lastAlert = this.alert
    this.shiftMessage()
    this.$emit('close', lastAlert)
  }
}
