<template>
  <div class="element-icon inline-block w-5 h-5 cursor-pointer" />
</template>

<script>
export default {
}
</script>
<style lang="postcss" scoped>
.element-icon {
  vertical-align: sub;
  background-image: url("~assets/img/question_def.svg");
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.element-icon:hover {
  background-image: url("~assets/img/question_hov.svg");
}
</style>
