
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

import { AxiosError } from 'axios'
import InputField from '~/components/form/input-field.vue'
import Btn from '~/components/element/btn.vue'
import { isAxiosError } from '~/util/vue-helper'
import { Alert } from '~/types'
import BalloonTip from '~/components/element/balloon-tip.vue'
import QuestionCircleIcon from '~/components/element/icon/question-circle.vue'
import LoginStepAnimation from '~/components/get-start/login-step-animation.vue'

const alertStore = namespace('alert')

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  components: {
    InputField,
    Btn,
    BalloonTip,
    QuestionCircleIcon,
    LoginStepAnimation,
  },
})
export default class extends Vue {
  private email = ''

  private isLoading = false
  private isSuccess = false
  private hasPreviousError = false

  @alertStore.State
  private messages!: Alert[]

  @alertStore.Mutation
  private setMessage!: (msg: Alert) => void

  get emailErrors (): string[] {
    const errors: string[] = []
    if (!this.$v.email.$dirty) {
      return errors
    }

    !this.$v.email.email && errors.push('メールアドレスが不正なフォーマットです')
    !this.$v.email.required && errors.push('メールアドレスは必須です')
    return errors
  }

  get hasLoginError (): boolean {
    if (!this.messages.length) {
      return this.hasPreviousError
    }

    return !!this.messages[0].isLoginError
  }

  get inputClasses (): string[] {
    const classes: string[] = []
    if (this.$v.email.$dirty && this.$v.email.$invalid) {
      classes.push('bg-error-light border-error')
    }
    return classes
  }

  private async submit (): Promise<void> {
    this.$v.$touch()
    if (this.$v.$invalid) {
      return
    }
    this.isLoading = true
    this.isSuccess = false
    const fd = new FormData()
    fd.append('email', this.email)
    try {
      this.$axios.setHeader('Host2', window.location.hostname)
      await this.$axios.post('/api/magiclink', fd)
    } catch (e) {
      this.isLoading = false
      if (isAxiosError(e)) {
        this.showError(e)
      } else {
        throw e
      }
      return
    }
    window.localStorage.setItem('emailForSignIn', this.email)
    this.isSuccess = true
    this.goComplete()
  }

  private goComplete (): void {
    this.$router.push('/get-start/complete')
  }

  private showError (e: AxiosError): void {
    if (e.response && e.response.status === 400) {
      switch (e.response.data.code) {
        case 'NOT_EXISTING_DOMAIN':
        case 'MISSING_EMAIL':
        case 'INVALID_EMAIL':
          this.setMessage({
            title: '無効なメールアドレスです',
            messageWithHTML: '再度メールアドレスを<br>入力してください。',
          })
          break
        default:
          this.setMessage({
            title: '何か問題が起きました',
            messageWithHTML: '再度メールアドレスを<br>入力してください。',
          })
      }
    } else {
      throw e
    }
  }

  private onCloseBalloon (lastAlert?: Alert): void {
    this.hasPreviousError = !!lastAlert && !!lastAlert.isLoginError
  }
}
